const allProjects = [
  {
    title: 'Web3 Laliberté',
    img: require('../img/Web3LApp.png'),
    description: 'Engineered a donation and gift fulfilment platform as a minimal viable prototype for a potential idea for supporting a charity that advocates for Web3.',
    githubUrl: 'https://github.com/PubXpo/Web3Laliberte.WebApp',
    techStack: ['C#', 'React', 'Docker'],
  },
  {
    title: 'Timepeace',
    img: "https://raw.githubusercontent.com/PubXpo/timepeace/main/img/landing_screen.png",
    description: 'Engineered a web portal application to empower contractors in tracking billable projects and seamlessly generating client invoices.',
    githubUrl: 'https://github.com/PubXpo/timepeace',
    techStack: ['Java', 'Bootstrap', 'MySQL'],
  },
  {
    title: 'Vessel v Landings',
    img: require('../img/VessVLand.PNG'),
    description: 'Built a data visualization app to predict correlations of UK vessel lengths and analyze their fish import trends using machine learning techniques.',
    githubUrl: 'https://github.com/PubXpo/VesVLand',
    techStack: ['Python', 'Docker', 'MongoDB'],
  },
  {
    title: 'EasyCal',
    img: require('../img/easycal.png'),
    description: 'Developed an intuitive Android application to enhance accessibility for visually impaired students, enabling easy access to academic calendars and improved planning.',
    githubUrl: 'https://github.com/PubXpo/easycal',
    techStack: ['Kotlin', 'XML', 'Android Studio'],
  },
];

export default allProjects;