import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container } from "react-bootstrap";
import "./CSS/About.css";

function About() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>About | Lee</title>
        </Helmet>
      </HelmetProvider>
  
      <Container fluid className="about-wrapper">
        <div className="about-left animate__animated animate__zoomIn">
          <h3>About</h3>
          <h4>
            ───&nbsp;&nbsp;Page <strong>02</strong>
          </h4>
        </div>
        <div className="about-right animate__animated animate__fadeIn animate__slower py-3">
        <p>
            Hello and welcome to my portfolio! I am an inventive and
            solution-oriented engineer with a strong interest in technology.
            With over ten years of professional experience and a diversified set
            of accomplishments, I offer a fresh perspective and a depth of
            knowledge to every project I work on. 
            <br/><br/>
            Throughout my career, I have been motivated by a strong sense of integrity,
            placing a high value on producing high-quality work, adjusting to changes, 
            and generating measurable outcomes. During the latter years of my career, 
            I had a defining moment that encouraged me to pursue my genuine passion for
            coding. I took the daring step of redirecting my educational goals
            and job path, fully embracing the dynamic world of technology. This
            transforming path has enabled me to develop a diverse skill set that
            distinguishes me from my contemporaries.
            <br/><br/>
            Feel free to explore my featured skills and projects showcased in this portfolio.
            It would be my pleasure to engage with you and explore future
            partnership opportunities.
          </p>
        </div>
      </Container>
    </>
  );
}

export default About;