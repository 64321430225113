import { Container } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./CSS/Skills.css";

const skills = [
  {
    category: "Programming Languages",
    icons: [
      { className: "devicon-python-plain-wordmark colored", title: "Python" },
      { className: "devicon-java-plain-wordmark colored", title: "Java" },
      { className: "devicon-kotlin-plain colored", title: "Kotlin" },
      { className: "devicon-cplusplus-plain colored", title: "C++" },
    ],
  },
  {
    category: "Cloud & Platforms",
    icons: [
      { className: "devicon-csharp-plain colored", title: "C#" },
      { className: "devicon-dotnetcore-plain colored", title: ".NET Core" },
      { className: "devicon-amazonwebservices-plain-wordmark colored", title: "AWS" },
      { className: "devicon-azure-plain-wordmark colored", title: "Azure" },
    ],
  },
  {
    category: "Web Development",
    icons: [
      { className: "devicon-html5-plain-wordmark colored", title: "HTML / XHTML" },
      { className: "devicon-css3-plain-wordmark colored", title: "CSS" },
      { className: "devicon-javascript-plain colored", title: "JavaScript" },
      { className: "devicon-typescript-plain colored", title: "TypeScript" },
    ],
  },
  {
    category: "Frameworks & Libraries",
    icons: [
      { className: "devicon-react-original-wordmark colored", title: "ReactJS" },
      { className: "devicon-nodejs-plain colored", title: "Node.js" },
      { className: "devicon-spring-plain-wordmark colored", title: "Spring Boot" },
      { className: "devicon-bootstrap-plain-wordmark colored", title: "Bootstrap" },
    ],
  },
  {
    category: "DevOps & Tools",
    icons: [
      { className: "devicon-docker-plain-wordmark colored", title: "Docker" },
      { className: "devicon-bash-plain colored", title: "Bash" },
      { className: "devicon-mongodb-plain-wordmark colored", title: "MongoDB" },
      { className: "devicon-mysql-plain-wordmark colored", title: "MySQL" },
    ],
  },
  {
    category: "Other Tools",
    icons: [
      { className: "devicon-sass-original colored", title: "Sass" },
      { className: "devicon-androidstudio-plain colored", title: "Android Studio" },
      { className: "devicon-jupyter-plain colored", title: "Jupyter" },
      { className: "devicon-git-plain colored", title: "Git" },
    ],
  },

  {
    category: "CI/CD & Automation",
    icons: [
      { className: "devicon-kubernetes-plain-wordmark colored", title: "Kubernetes" },
      { className: "devicon-terraform-plain colored", title: "Terraform" },
      { className: "devicon-jenkins-plain colored", title: "Jenkins" },
      { className: "devicon-github-plain colored", title: "GitHub Actions" },
    ],
  },
 
];

function Skills() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Skills | Lee</title>
        </Helmet>
      </HelmetProvider>
      <Container fluid className="skills-wrapper">
        <div className="skills-left animate__animated animate__zoomIn">
          <h3>Skills</h3>
          <h4>
            ───&nbsp;&nbsp;Page <strong>03</strong>
          </h4>
        </div>
        <div className="skills-right">
          {skills.map((skillCategory, index) => (
            <div key={index} className="row my-4 animate__animated animate__flipInX animate_slow">
              <div className="col">
                
                {skillCategory.icons.map((icon, idx) => (
                  <i key={idx} className={icon.className} title={icon.title}></i>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}

export default Skills;