import { AiFillGithub, AiOutlineSend } from "react-icons/ai";
import "./CSS/Footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className="px-4">
        <div className="foot-left d-flex">
          <p>Engineered by Lee &copy; {currentYear}</p>
        </div>
        <div className="foot-right d-flex">
          <a
            href="https://github.com/PubXpo"
            target="_blank"
            rel="noreferrer"
          >
            <AiFillGithub className="foot-icon" />
            GitHub
          </a>

          <a
            href="mailto:lee@zlaliberte.com" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <AiOutlineSend className="foot-icon" />
            Compose
          </a>
        </div>
      </footer>
    </>
  );
}

export default Footer;